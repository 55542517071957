import { Link } from 'react-router-dom'
import s from './styles.module.css'

const TermsBox = props => {

    return (
        <div className={s.flex}>
            <input type="checkbox" defaultChecked={props.status} onChange={props.onChange} />
            <p >I Agree with site <Link to="/termss">Privacy Policies</Link> and <Link to="/termss">Terms</Link> Agreements</p>
        </div>

    )
}

export default TermsBox