import { ORDERS_SET, ORDER_SET } from '../actions/orders'

export const orders = (state = [], action) => {
   switch (action.type) {
      case ORDERS_SET:
         return action.list;

      case ORDER_SET:
         return state.map(order => {
            if (order.id === action.order.id) {
               return action.order;
            }
            return order;
         });

      default: return state;
   }
}

export default orders;