import s from './styles.module.css';


const Line = props => {

   return (
      <div className={s.line}></div>

   )
}

export default Line;