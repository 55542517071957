import { ICONS } from '../../constants';
import s from './styles.module.css';


const Notification = props => {
    let classes = s.notification;
    classes = props.styles === `green` ? classes + ` ` + s.green : classes;

    return (
        <div className={classes}>
            <img src={ICONS.notification} alt="Notification"/>
            <p>{props.text}</p>
        </div>
    )
}

export default Notification