import { Link } from 'react-router-dom';
import { ICONS } from '../../constants';
import s from './styles.module.css';


const LogoImg = props => {

   return (
      <div className={s.flex}>
         <Link to="/">
            <img src={ICONS.logoImg} alt="Logo" />
         </Link>
         <div className={s.logoTxt}>
            <p style={{color: props.color}}>Guezta</p>
            <p>Best white seo platform</p>
         </div>
      </div>

   )
}

export default LogoImg;