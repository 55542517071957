import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import s from '../../pages/Registration/styles.module.css'
import Line from '../../components/Line'
import Text from '../../components/Text'
import Button from '../../components/Button'
import Input from '../../components/Input'
import Title from '../../components/Title'
import { showLoader } from '../../actions/loader'
import { showError } from '../../actions/error'
import req from '../../utils/axios'

const BuyerLogin = props => {
   const dispatch = useDispatch()
   const loader = useSelector(state => state.loader)
   const [email, changeEmail] = useState(``);
   const [pass, changePass] = useState(``);

   const setEmail = e => changeEmail(e.target.value);
   const setPass = e => changePass(e.target.value);

   const handleSubmit = async e => {
      if (!email) {
         dispatch(showError({ status: true, text: `Email is required` }));
         return;
      }
      if (!pass) {
         dispatch(showError({ status: true, text: `Password is required` }));
         return;
      }
      dispatch(showLoader(true));

      try {
         const { data: token } = await req.post(`/buyer/login`, {
            email,
            pass
         });

         localStorage.setItem(`token`, token);
         window.location.href = `/buyer/panel`;

      } catch (error) {
         if (error.response && error.response.data) {
            dispatch(showError({ status: true, text: error.response.data }));
         } else {
            dispatch(showError({ status: true, text: error.message }));
         }
      }

      dispatch(showLoader(false));
   }

   return (
      <div className={s.register}>
         <div className={s.title}>
            <Title text="Buyer login" />
         </div>

         <div className={s.desc}>
            <Text text="This page is for authorized buyers only" />
         </div>

         <Line />

         <div className={s.email}>
            <Input title="Email" placeholder="Email here…" value={email} onChange={setEmail} />
         </div>

         <div className={s.password}>
            <Input title="Password" placeholder="Type your password" type="password" value={pass} onChange={setPass} />
         </div>

         <div className={s.submit}>
            <Button
               style={{ color: "#fff", backgroundColor: "#0B6AFF" }}
               title="Login"
               onClick={handleSubmit}
               loader={loader} />
         </div>

         <p className={s.haveAcc}>Don’t have an account? <Link to="/registration">Register</Link></p>
      </div>
   )
}

export default BuyerLogin;