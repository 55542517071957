import { REGISTRATION_SET } from '../actions/registration'

export const registration = (state = false, action) => {
   switch (action.type) {
      case REGISTRATION_SET:
         return action.bool;

      default: return state;
   }
}

export default registration;