import req from '../utils/axios';
import { showError } from '../actions/error';
export const BUYER_SET = `BUYER/SET`;
export const REASON_SET = `REASON/SET`;

export const setBuyer = buyer => {
   return {
      type: BUYER_SET,
      buyer
   }
}

export const setReason = (id, reason) => {
   return {
      type: REASON_SET,
      id,
      reason
   }
}

export const getBuyer = token => {
   return async (dispatch, getState) => {
      try {
         const { data: buyer } = await req.get(`/buyer/auth/${token}`);
         if (!buyer) {
            window.location.href = `/buyer/login`;
            return;
         }
         dispatch(setBuyer(buyer));

      } catch (error) {
         dispatch(showError({ status: true, text: error.message }));
      }
   }
}