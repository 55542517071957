export const truncate = (string, length, dotted) => {
    if (string.length > length) return string.substr(0, length) + (dotted ? `...` : ``);
    else return string;
}

export const makeup = (numberStr, toFixed) => {
    const niceNumber = parseFloat(numberStr).toFixed(toFixed);
    return niceNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ` `);
}

export const capitalize = (string, isLower) => {
    if (isLower) string = string.toLowerCase();
    return string.replace(/(?:^|\s)\S/g, a => a.toUpperCase());
}