import { Link } from 'react-router-dom';
import s from './styles.module.css';
import { ICONS } from "../../constants";

const Main = props => {

   return (
      <>
         <div className={s.centered}>
            <img src={ICONS.tetris} alt="Text Logo Guezta" />
            <h1>Guest<br />Posting Service</h1>
            <p>Get quality posts from relevant sites with traffic: thousands of publishers & outreachers</p>
            <div className={s.flex}>
               <Link to="/login">Log In</Link>
               <Link to="/registration">Register</Link>
            </div>
         </div>

         <div className={s.mozaika}>
            <img src={ICONS.leftMozaika} alt="Left Mozaika" />
            <img src={ICONS.rightMozaika} alt="Right Mozaika" />
         </div>

         <div className={s.subscribe}>
            <p>Sign up for companies’ special offers and webinars:</p>
            <input placeholder="Your email here…" type="text" />
            <p>You can unsubscribe at any time. Read our <Link to="/termss">privacy policy</Link> and <Link to="/termss">terms</Link>...</p>
         </div>

         <div className={s.support}>
            <div>
               <img src={ICONS.light} alt="Left Mozaika" />
               <p>Need Help? <Link to="/contacts">Contact us</Link></p>
            </div>
            <div>
               <img src={ICONS.bug} alt="Left Mozaika" />
               <p>Found a Bug? <Link to="/contacts">Report</Link></p>
            </div>
         </div>
      </>

   )
}

export default Main;
