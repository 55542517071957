import { POTENCIAL_SET } from '../actions/potencial'

export const potencial = (state = 0, action) => {
   switch (action.type) {
      case POTENCIAL_SET:
         return action.amount;

      default: return state;
   }
}

export default potencial;