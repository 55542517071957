import { combineReducers } from 'redux'
import { options } from './options'
import { loader } from './loader'
import { error } from './error'
import { user } from './user'
import { promo } from './promo'
import { potencial } from './potencial'
import { orders } from './orders'
import { registration } from './registration'
import { buyer } from './buyer'


export const rootReducer = combineReducers({
   options,
   loader,
   error,
   user,
   promo,
   potencial,
   orders,
   registration,
   buyer
});