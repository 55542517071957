import { USER_SET } from '../actions/user'

export const user = (state = null, action) => {
   switch (action.type) {
      case USER_SET:
         return action.user;

      default: return state;
   }
}

export default user;