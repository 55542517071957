import axios from 'axios';

const req = axios.create({
   // When you run npm start, it is always equal to 'development', 
   // when you run npm test it is always equal to 'test', and when 
   // you run npm run build to make a production bundle, it is always 
   // equal to 'production'. You cannot override NODE_ENV manually.
   baseURL: process.env.NODE_ENV === `development` ? `http://localhost:6050` : `https://api.guezta.com`,
   timeout: 4000,
});

export default req;