import { useDispatch, useSelector } from 'react-redux';
import s from './styles.module.css'
import { setActiveOption } from '../../actions/options'


const Options = props => {
   const dispatch = useDispatch();
   const promo = useSelector(state => state.promo);

   const handleOptionChange = e => {
      dispatch(setActiveOption(Number(e.target.id)));
   }

   return (
      <div className={s.options}>
         {
            props.options.map((el, i) => {
               return <div className={(promo && i) ? s.disabled : null} onClick={promo ? null : handleOptionChange} key={i} id={i} style={{borderColor: el.isActive ? `#0B6AFF` : null}} >
                  <div className={s.texts}>
                     <p>{el.title}</p>
                     {(promo && i === 0) ? <p>You're registering as <span className={s.blue}>{promo.domain}</span> publisher</p> : <p>{el.desc}</p>}
                  </div>
                  <div className={s.optionCircle}>
                     {
                        el.isActive ? <div></div> : null
                     }
                  </div>
               </div>
            })
         }
      </div>

   )
}

export default Options;