export const ORDERS_SET = `ORDERS/SET`;
export const ORDER_SET = `ORDER/SET`;

export const setOrders = list => {
   return {
      type: ORDERS_SET,
      list
   }
}

export const setOrder = order => {
   return {
      type: ORDER_SET,
      order
   }
}