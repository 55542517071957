import { Link } from 'react-router-dom';
import { ICONS } from '../../constants';
import s from './styles.module.css';


const LogoTxt = props => {

   return (
      <div className={s.flex}>
         {
            props.url
               ?
               <a href={props.url}><img src={ICONS.logoImg} alt="Logo" /></a>
               :
               <Link to="/">
                  <img src={ICONS.logoImg} alt="Logo" />
               </Link>
         }

         <div className={s.logoTxt}>
            <p style={{ color: props.color }}>Guezta</p>
            <p>Best white seo platform</p>
         </div>
      </div>

   )
}

export default LogoTxt;