import s from './styles.module.css'

const NewsMarker = props => {
    return (
        <div className={s.counter}>
            <svg width="20px" height="20px" viewBox="0 0 20 20">
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g transform="translate(-1259.000000, -27.000000)" fillRule="nonzero">
                        <g id="news" transform="translate(1259.000000, 27.000000)">
                            <path d="M7.07149068,1.96048889 C8.03455722,1.73457778 9.01733486,1.62162222 10,1.62162222 C10.4477791,1.62162222 10.8107791,1.25861111 10.8107791,0.810811111 C10.8107791,0.363011111 10.4477791,0 10,0 C8.89300154,0 7.7860017,0.127244444 6.70116851,0.381722222 C3.56555784,1.11723333 1.11723596,3.56554444 0.381724947,6.70115556 C-0.127241649,8.87088889 -0.127241649,11.1291111 0.381724947,13.2988889 C1.11723596,16.4344444 3.56555784,18.8827778 6.70116851,19.6183333 C8.87089043,20.1272222 11.1291123,20.1272222 13.2988898,19.6183333 C16.4344449,18.8827778 18.8827779,16.4344444 19.6183334,13.2988889 C19.8727778,12.2138889 20,11.107 20,10 C20,9.55222222 19.6370001,9.18911111 19.1892223,9.18911111 C18.7414446,9.18911111 18.3783336,9.55222222 18.3783336,10 C18.3783336,10.9826667 18.2654447,11.9654444 18.0395558,12.9285556 C17.444667,15.4645556 15.4645562,17.4446667 12.9285565,18.0395556 C11.0023346,18.4913333 8.99766819,18.4913333 7.07149068,18.0395556 C4.53549104,17.4446667 2.55535798,15.4645556 1.96049139,12.9285556 C1.50866923,11.0023333 1.50866923,8.99766667 1.96049139,7.07148889 C2.55535798,4.53548889 4.53549104,2.55535556 7.07149068,1.96048889 Z" fill="#C6CED7"></path>
                            <path d="M16.4864449,0.540544444 C14.5460008,0.540544444 12.973001,2.11358889 12.973001,4.05405556 C12.973001,5.99451111 14.5460008,7.56756667 16.4864449,7.56756667 C18.4270002,7.56756667 20,5.99451111 20,4.05405556 C20,2.11358889 18.4270002,0.540544444 16.4864449,0.540544444 Z" fill={props.fill}></path>
                        </g>
                    </g>
                </g>
            </svg>
            <p>{props.count}</p>
        </div>
    )

}

export default NewsMarker