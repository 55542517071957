import { LOADER_SET } from '../actions/loader'

const initState = false

export const loader = (state = initState, action) => {
    switch (action.type) {
        case LOADER_SET:
            return action.state

        default: return state
    }
}

export default loader