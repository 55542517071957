import { Outlet } from 'react-router-dom'
import SideMenu from '../components/SideMenu'
import TopMenu from '../components/TopMenu'
import s from './styles.module.css'

const PublisherLayout = props => {
   return (
      <>
         <div className={s.publisher}>
            <div className={s.left}>
               <SideMenu />
            </div>
            <div className={s.right}>
               <TopMenu />
               <Outlet />
            </div>
         </div>
      </>
   )
}

export default PublisherLayout