const CopyButton = props => {
    const handleCopy = e => {
        copyTextToClipboard(props.copyText);
    }

    return (
        <svg onClick={handleCopy} width="20px" height="20px" viewBox="0 0 20 20">
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g transform="translate(-654.000000, -652.000000)" fill="#C6CED7" fillRule="nonzero">
                    <g transform="translate(654.000000, 652.000000)">
                        <path d="M19.4738322,4.02770375 L15.9722962,0.526167803 C15.636779,0.18816172 15.1798335,-0.00133954058 14.7035792,7.1286792e-06 L8.97435254,7.1286792e-06 C6.85111926,0.00227549981 5.13046262,1.72293214 5.12820145,3.84616542 L5.12820145,4.1025755 L3.84615109,4.1025755 C1.72291781,4.10483666 0.00226116623,5.8254933 0,7.94872659 L0,16.1538489 C0.00226116623,18.2770822 1.72291781,19.9977388 3.84615109,20.0000071 L11.0256331,20.0000071 C13.1488664,19.9977388 14.869523,18.2770822 14.8717842,16.1538489 L14.8717842,15.8974388 L16.1538346,15.8974388 C18.2770679,15.8951777 19.9977245,14.174521 20,12.0512877 L20,5.29642079 C20.0013395,4.82016655 19.8118383,4.36322101 19.4738322,4.02770375 L19.4738322,4.02770375 Z M15.8974245,2.62667912 L17.3733209,4.1025755 L16.9866545,4.1025755 C16.3855576,4.10144667 15.8985533,3.61444242 15.8974245,3.01334551 L15.8974245,2.62667912 Z M13.3333238,16.1538489 C13.3321928,17.4278825 12.2996667,18.4604086 11.0256331,18.4615396 L3.84615109,18.4615396 C2.57211749,18.4604086 1.5395914,17.4278825 1.53846044,16.1538489 L1.53846044,7.94872659 C1.5395914,6.67469299 2.57211749,5.6421669 3.84615109,5.64103593 L5.12820145,5.64103593 L5.12820145,12.0512877 C5.13046262,14.174521 6.85111926,15.8951777 8.97435254,15.8974388 L13.3333238,15.8974388 L13.3333238,16.1538489 Z M16.1538346,14.3589784 L8.97435254,14.3589784 C7.70031894,14.3578474 6.66779286,13.3253213 6.66666189,12.0512877 L6.66666189,3.84616542 C6.66779286,2.57213182 7.70031894,1.53960574 8.97435254,1.53847477 L14.3589641,1.53847477 L14.3589641,3.01334551 C14.3606596,4.46387596 15.536124,5.63934039 16.9866545,5.64103593 L18.4615252,5.64103593 L18.4615252,12.0512877 C18.4603943,13.3253213 17.4278682,14.3578474 16.1538346,14.3589784 Z" id="copy-Regular-2"></path>
                    </g>
                </g>
            </g>
        </svg>
    )
}

export const fallbackCopyTextToClipboard = text => {
    const textArea = document.createElement(`textarea`);
    textArea.value = text;

    // Avoid scrolling to bottom
    textArea.style.top = `0`;
    textArea.style.left = `0`;
    textArea.style.position = `fixed`;

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
        var successful = document.execCommand(`copy`);
        var msg = successful ? `successful` : `unsuccessful`;
        console.log(`Fallback: Copying text command was ` + msg);
    } catch (err) {
        console.error(`Fallback: Oops, unable to copy`, err);
    }

    document.body.removeChild(textArea);
}

export const copyTextToClipboard = text => {
    if (!navigator.clipboard) {
        fallbackCopyTextToClipboard(text);
        return;
    }

    navigator.clipboard.writeText(text).then(() => {}, err => {
        console.error(`Async: Could not copy text: `, err);
    });
}

export default CopyButton;