import Loader from '../Loader'

const Button = props => {

   return (
      <button onClick={props.onClick} style={props.style}>
         {props.loader ? null : <p>{props.title}</p>}
         <Loader isShown={props.loader} />
      </button>
      
   )
}

export default Button;