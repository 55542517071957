import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import { rootReducer } from './reducers/root';
import './index.css';
import './fonts/aeonik/Aeonik-Bold.otf';
import './fonts/aeonik/Aeonik-Medium.otf';
import './fonts/aeonik/Aeonik-Regular.otf';
import App from './App';

const store = createStore(
   rootReducer,
   applyMiddleware(thunk)
);

const container = document.getElementById(`root`);
const root = createRoot(container);
root.render(
   <BrowserRouter>
      <Provider store={store}>
         <App />
      </Provider>
   </BrowserRouter>
);