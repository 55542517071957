import { OPTIONS_SET } from '../actions/options'

const initState = [
   {
      title: `PUBLISHER`,
      desc: `For those who want to make money on their site content`,
      isActive: true
   },
   {
      title: `BUYER`,
      desc: `For those who want to get backlinks and raise traffic`
   }
]

export const options = (state = initState, action) => {
   switch (action.type) {
      case OPTIONS_SET:
         return state.map((el, i) => {
            el.isActive = false;
            if (i === action.index) el.isActive = true;
            return el;
         });

      default: return state;
   }
}

export default options;