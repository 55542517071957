import { useEffect, useCallback, useRef } from 'react'
import s from './styles.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { showError } from '../../actions/error';

const Error = props => {
    const fullScreen = useRef(null);
    const dispatch = useDispatch();
    const { status, text } = useSelector(state => state.error);

    const handleWindowClick = useCallback(
        (e) => {
            if (status && fullScreen.current?.contains(e.target)) dispatch(showError({ status: false, text: `` }));
        },
        [dispatch, status]
    );

    useEffect(() => {
        document.addEventListener(`click`, handleWindowClick);
        return () => {
            document.removeEventListener(`click`, handleWindowClick);
        }
    }, [handleWindowClick]);

    return (
        <div ref={fullScreen} className={s.fullScreen} style={{ display: status ? `block` : `none` }}>
            <div className={s.error}>
                <p>{text}</p>
            </div>
        </div>
    )
}

export default Error;