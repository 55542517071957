import { Outlet } from 'react-router-dom'
import LogoTxt from '../components/LogoTxt'
import s from './styles.module.css'

const EmptyLayout = props => {

   return (
      <div className={s.centered}>
         <LogoTxt color="#202124" />
         <Outlet />
      </div>

   )
}

export default EmptyLayout