import s from './styles.module.css';

const Input = props => {
   
   return (
      <div className={s.input}>
         <p>{props.title}</p>
         <input 
            ref={props.innerRef}
            type={props.type || "text"}
            placeholder={props.placeholder}
            value={props.value}
            onChange={props.onChange} />
      </div>

   )
}

export default Input;