import { BUYER_SET, REASON_SET } from '../actions/buyer'

export const buyer = (state = null, action) => {
   switch (action.type) {
      case BUYER_SET:
         action.buyer.orders = action.buyer.orders.filter(el => el.status === `reviewing`);
         action.buyer.orders = action.buyer.orders.map(el => {
            el.date = el.date + (6 * 24 * 60 * 60 * 1000);
            return el;
         });
         return action.buyer;

      case REASON_SET:
         const orders = state.orders.map(el => {
            if (el.id === action.id) {
               el.reason = action.reason;
               return el;
            } else return el;
         });
         const newState = {
            ...state,
            orders
         }
         return newState;

      default: return state;
   }
}

export default buyer;