import { useRef } from 'react';
import req from '../../utils/axios'
import { Link, useLocation } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import s from './styles.module.css';
import Title from '../../components/Title'
import Text from '../../components/Text'
import Line from '../../components/Line'
import Input from '../../components/Input'
import Button from '../../components/Button'
import Offer from '../../components/Offer'
import Notification from '../../components/Notification'
import { showError } from '../../actions/error'
import { showLoader } from '../../actions/loader'

const Offers = props => {
   const publisher = useSelector(state => state.user);
   const promo = useSelector(state => state.promo);
   const potencial = useSelector(state => state.potencial);
   const orders = useSelector(state => state.orders);

   if (promo) {
      return <OffersPromo promo={promo} potencial={potencial} orders={orders} />
   }

   if (publisher) {
      if (!promo && !publisher.domain) {
         return <AddSite />
      }
      return <OffersPublisher orders={orders} />
   }
}

const OffersPromo = ({ promo, potencial, orders }) => {
   const route = useLocation();

   return (
      <>
         <p className={s.promo}>🎉 This offer is for <span>{promo.domain}</span> publisher. <Link to="/registration">Register</Link> on the platform to accept it.</p>

         <div className={`${s.title} ${s.mini}`}>
            <Title text={`New Guest Post Offers for $${potencial}`} />
         </div>

         <div className={s.desc}>
            <Text text="Read these offers from clients and accept if they suit you" />
         </div>

         <div className={`${s.flex} ${s.types}`}>
            <Link className={route.pathname.includes(`/publisher/new`) ? s.active : null} to="/publisher">New</Link>
            <Link className={route.pathname.includes(`rejected`) ? s.active : null} to="/publisher/rejected">Rejected</Link>
         </div>

         <div className={s.offersList}>
            {
               orders.length ?
                  orders.map(offer => {
                     return (
                        <Offer key={offer.id} {...offer} />
                     )
                  })
                  :
                  <Notification text="You don’t have any offers yet! Be patient, we will notify you when you will get one." />
            }
         </div>

      </>
   )
}

const OffersPublisher = ({ orders }) => {
   const route = useLocation();
   const getRejectedOffers = () => {
      const rejected = orders.filter(el => el.status === `rejected`);
      const jsx = rejected.map(offer => <Offer key={offer.id} {...offer} />);
      return jsx.length ? jsx : < Notification text="We're glad to see you didn't reject any offer! :)" />
   }

   const getPotencialOffers = () => {
      const potencial = orders.filter(el => {
         if (el.status === null) return true;
         else if (el.status === `accepted`) return true;
         else if (el.status === `reviewing`) return true;
         else if (el.status === `returned`) return true;
         else return false;
      });
      const jsx = potencial.map(offer => <Offer key={offer.id} {...offer} />);
      return jsx.length ? jsx : <Notification text="You don’t have any offers yet! Be patient, we will notify you when you will get one." />
   }

   return (
      <>
         <div className={`${s.title} ${s.mini}`}>
            <Title text="New Offers" />
         </div>

         <div className={s.desc}>
            <Text text="Read these offers from clients and accept if they suit you" />
         </div>

         <div className={`${s.flex} ${s.types}`}>
            <Link className={route.pathname === `/publisher` ? s.active : null} to="/publisher">New</Link>
            <Link className={route.pathname.includes(`rejected`) ? s.active : null} to="/publisher/rejected">Rejected</Link>
         </div>

         <div className={s.offersList}>
            {
               route.pathname.includes(`rejected`) ? getRejectedOffers() : getPotencialOffers()
            }
         </div>
      </>
   )
}

const AddSite = props => {
   const urlInput = useRef(null);
   const dispatch = useDispatch();
   const loader = useSelector(state => state.loader)

   const handleClick = async e => {
      const url = urlInput.current.value;

      if (!url) {
         dispatch(showError({ status: true, text: `Site URL is required` }));
         return;
      }

      dispatch(showLoader(true));
      try {
         const token = localStorage.getItem(`token`);
         await req.post(`/add-site/${token}`, { url });
         window.location.href = `/publisher`;

      } catch (error) {
         if (error.response && error.response.data) {
            dispatch(showError({ status: true, text: error.response.data }));
         } else {
            dispatch(showError({ status: true, text: error.message }));
         }
      }
      dispatch(showLoader(false));
   }

   return (
      <>
         <div className={s.title}>
            <Title text="Add site" />
         </div>

         <div className={s.desc}>
            <Text text="You have to add your site or blog to earn money from guest posts" />
         </div>

         <Line />

         <div className={s.email}>
            <Input innerRef={urlInput} title="Your site URL" placeholder="Example: https://mysite.com" />
         </div>

         <div className={s.submit}>
            <Button
               style={{ color: "#fff", backgroundColor: "#0B6AFF" }}
               title="Add site"
               onClick={handleClick}
               loader={loader} />
         </div>
      </>
   )

}

export default Offers