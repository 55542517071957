import { Outlet } from 'react-router-dom';
import s from './styles.module.css';



const DarkLayout = props => {

   return (
      <div className={s.dark}>
         <Outlet />
      </div>
   )
}


export default DarkLayout;