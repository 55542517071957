import React from 'react';
import { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import DarkLayout from './layouts/DarkLayout';
import EmptyLayout from './layouts/EmptyLayout';
import ErrorLayout from './layouts/ErrorLayout';
import PublisherLayout from './layouts/PublisherLayout';
import PublicNav from './components/PublicNav';
import PublisherNav from './components/PublisherNav';
import Error404 from './pages/Error404';
import Main from './pages/Main';
import Registration from './pages/Registration';
import Login from './pages/Login';
import Contacts from './pages/Contacts';
import Offers from './pages/Offers';
import Completed from './pages/Completed';
import Earnings from './pages/Earnings';
import BuyerLogin from './pages/BuyerLogin';
import BuyerLayout from './layouts/BuyerLayout';
import BuyerPanel from './pages/BuyerPanel';
import ReactGA from 'react-ga';
import { TRACKING_ID } from './constants';
ReactGA.initialize(TRACKING_ID);


const App = () => {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <Routes>
      <Route element={<ErrorLayout />}>
        <Route path="/" element={<DarkLayout />}>
          <Route element={<PublicNav />}>
            <Route index element={<Main />} />
          </Route>
        </Route>

        <Route path="/" element={<EmptyLayout />}>
          <Route path="registration" element={<Registration />} />
          <Route path="login" element={<Login />} />
          <Route path="contacts" element={<Contacts />} />
          <Route path="buyer/login" element={<BuyerLogin />} />
          <Route element={<BuyerLayout />}>
            <Route path="buyer/panel" element={<BuyerPanel />} />
          </Route>
        </Route>

        <Route path="publisher" element={<PublisherNav />}>
          <Route element={<PublisherLayout />}>
            <Route index element={<Offers />} />
            <Route path="new/:emailID" element={<Offers />} />
            <Route path="rejected" element={<Offers />} />
            <Route path="completed" element={<Completed />} />
            <Route path="earnings" element={<Earnings />} />
          </Route>
        </Route>

        <Route path="*" element={<Error404 />} />

      </Route>
    </Routes>
  )
}

export default App;