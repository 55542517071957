import { useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import s from './styles.module.css'


export const handleQuit = () => {
    localStorage.removeItem(`token`);
    window.location.href = `/`;
}

const SideMenu = props => {
    const { pathname } = useLocation();
    const publisher = useSelector(state => state.user);
    const promo = useSelector(state => state.promo);
    const potencial = useSelector(state => state.potencial);

    if (promo) {
        return <PromoSide potencial={potencial} promo={promo} pathname={pathname} />
    }

    if (publisher) {
        return <PublisherSide potencial={potencial} pathname={pathname} />
    }

    return <p>Loading...</p>
}

const PromoSide = ({ potencial, promo, pathname }) => {

    return (
        <>
            <div className={`${s.sideMenu} ${s.blur}`}>
                <div>
                    <Link className={pathname === `/publisher` || pathname.includes(`/new`) ? s.active : null} to="/publisher">My Offers</Link>
                    <p className={s.currentOffers}>Current offers: <span>${potencial}</span></p>
                </div>
                <div>
                    <Link className={pathname.includes(`completed`) ? s.active : null} to="/publisher/completed">Completed</Link>
                </div>
                <div>
                    <Link className={pathname.includes(`earnings`) ? s.active : null} to="/publisher/earnings">Earnings</Link>
                </div>
                <div>
                    <p onClick={handleQuit} className={s.quit}>Quit</p>
                </div>
            </div>
            <p className={s.promo}>Are you <span>{promo.domain}</span> publisher? <Link to="/registration">Register</Link> on the platform to accept this offer</p>
        </>
    )
}

const PublisherSide = ({ potencial, pathname }) => {

    return (
        <div className={s.sideMenu}>
            <div>
                <Link className={pathname === `/publisher` ? s.active : null} to="/publisher">My Offers</Link>
                <p className={s.currentOffers}>Current offers: <span>${potencial}</span></p>
            </div>
            <div>
                <Link className={pathname.includes(`completed`) ? s.active : null} to="/publisher/completed">Completed</Link>
            </div>
            <div>
                <Link className={pathname.includes(`earnings`) ? s.active : null} to="/publisher/earnings">Earnings</Link>
            </div>
            <div>
                <p onClick={handleQuit} className={s.quit}>Quit</p>
            </div>
        </div>
    )
}

export default SideMenu;