import { ICONS } from "../../constants";
import { handleQuit } from '../SideMenu';
import s from './styles.module.css';

const Exit = props => {
    return (
        props.view === `img`
        ?
        <img className={s.exitImg} onClick={handleQuit} src={ICONS.exit} alt="Exit"/>
        :
        <p className={s.exitTxt} onClick={handleQuit}>Quit</p>

    )
}

export default Exit