import { ERROR_SET } from '../actions/error'

const initState = {
    status: false,
    text: ``
}

export const error = (state = initState, action) => {
    switch (action.type) {
        case ERROR_SET:
            return {
                status: action.status,
                text: action.text
            }

        default: return state;
    }
}

export default error;