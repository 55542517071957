import { useDispatch } from 'react-redux';
import { useState, useRef } from 'react';
import s from '../../pages/Registration/styles.module.css'
import Line from '../../components/Line'
import Text from '../../components/Text'
import Button from '../../components/Button'
import Input from '../../components/Input'
import Textarea from '../../components/Textarea'
import Title from '../../components/Title'
import Notification from '../../components/Notification'
import { showError } from '../../actions/error'
import req from '../../utils/axios'

const Contacts = props => {
   const dispatch = useDispatch()
   const [loader, showLoader] = useState(false);
   const [notification, showNotification] = useState(false);
   const inputRef = useRef(null);
   const areaRef = useRef(null);

   const handleSubmit = async e => {
      const email = inputRef.current.value;
      const message = areaRef.current.value;

      if (!email) {
         dispatch(showError({ status: true, text: `Email is required` }));
         return;
      }
      if (!message) {
         dispatch(showError({ status: true, text: `Message is required` }));
         return;
      }
      showLoader(true);

      try {
         await req.post(`/contact`, {
            email,
            message
         });

         showNotification(true);

      } catch (error) {
         if (error.response && error.response.data) {
            dispatch(showError({ status: true, text: error.response.data }));
         } else {
            dispatch(showError({ status: true, text: error.message }));
         }
      }

      showLoader(false);
   }

   return (
      <div className={s.register}>
         <div className={s.title}>
            <Title text="Contacts" />
         </div>

         <div className={s.desc}>
            <Text text="Please fill out the message. Provide as much information as possible to get accurate answer" />
         </div>

         <Line />

         {
            notification
               ?
               <div className={s.notify}>
                  <Notification text="Thank you for your message! We will reply as soon as we can. Usually it can take up to 24 hours" />
               </div>
               :
               <>
                  <div className={s.email}>
                     <Input innerRef={inputRef} title="Your email" placeholder="Email here…" />
                     <div className={s.notification}>
                        <Notification text="This is the only way we can get back to you. Don't worry, we won't share it" />
                     </div>
                  </div>

                  <div className={s.message}>
                     <Textarea innerRef={areaRef} placeholder="Please, describe what happened?" title="Message" />
                  </div>

                  <div className={s.submit}>
                     <Button
                        style={{ color: "#fff", backgroundColor: "#0B6AFF" }}
                        title="Send"
                        onClick={handleSubmit}
                        loader={loader} />
                  </div>
               </>
         }

      </div>
   )
}

export default Contacts