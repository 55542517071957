import s from './styles.module.css'

const PublisherPromo = props => {

    return (
        <p className={s.publisherPromo}>
            You can create a publisher account and earn money from guest posts if you have a site! <a href="/registration">Register</a>
        </p>

    )
}

export default PublisherPromo