import s from './styles.module.css';


const Loader = props => {

    return (
        <div style={{opacity: props.isShown ? 1 : 0}} className={s.loader}>
            <div className={s.lf}></div>
            <div className={s.ls}></div>
            <div className={s.lt}></div>
        </div>

    )
}

export default Loader;