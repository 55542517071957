import logoImg from './icons/logo-img.svg';
import tetris from './icons/tetris.svg';
import inc from './icons/inc.svg';
import leftMozaika from './icons/left-mozaika.svg';
import rightMozaika from './icons/right-mozaika.svg';
import bug from './icons/bug.svg';
import light from './icons/light.svg';
import notification from './icons/notification.svg';
import exit from './icons/exit.svg';


export const ICONS = {
    logoImg,
    tetris,
    inc,
    leftMozaika,
    rightMozaika,
    bug,
    light,
    notification,
    exit
}

export const TRACKING_ID = `UA-245906616-1`;