import { useEffect } from 'react';
import { Outlet, Link, useParams } from 'react-router-dom'
import LogoTxt from '../LogoTxt'
import NewsMarker from '../NewsMarker'
import Exit from '../Exit'
import s from './styles.module.css'
import { useDispatch, useSelector } from 'react-redux'
import { getUser } from '../../actions/user'
import { getPromo } from '../../actions/promo'

const PublisherNav = props => {
   const dispatch = useDispatch();
   const { emailID } = useParams();
   const token = localStorage.getItem(`token`);
   const publisher = useSelector(state => state.user);
   const promo = useSelector(state => state.promo);
   const orders = useSelector(state => state.orders);


   useEffect(() => {
      if (token && emailID) {
         dispatch(getUser(token));
         return;
      }
      if (token && !emailID) {
         dispatch(getUser(token));
      }
      if (!token && emailID) {
         dispatch(getPromo(emailID));
         return;
      }
      if (!token && !emailID) {
         window.location.href = `/registration`;
         return;
      }

   }, [dispatch, token, emailID]);

   if (promo) {
      return <PromoNAV publisher={publisher} promo={promo} orders={orders} />
   }

   if (publisher) {
      return <PublisherNAV publisher={publisher} orders={orders} />
   }

   return <p>Loading...</p>
}

const PromoNAV = ({ publisher, promo, orders }) => {
   return (
      <>
         <nav className={s.publisherNav}>
            <div className={s.left}>
               <LogoTxt color="#202124" url="/publisher" />
            </div>

            <div className={s.middle}>
               <p className={s.promo}>🎉 This offer is for <span>{promo.domain}</span> publisher. <Link to="/registration">Register</Link></p>
            </div>

            <div className={`${s.right} ${s.blur}`}>
               <div className={s.balance}>
                  <div>
                     <p className={s.balanceTitle}>Balance:</p>
                     <Link className={s.transactions} to="/publisher/earnings">See transactions</Link>
                  </div>
                  <p className={s.amount}>${publisher ? publisher.balance : `0.00`}</p>
               </div>
               <div className={s.navIcons}>
                  <NewsMarker fill={orders.length ? `#0B6AFF` : `#C6CED7`} count={orders.length || null} />
                  <Exit view="img" />
               </div>
            </div>
         </nav>
         <Outlet />
      </>
   )
}

const PublisherNAV = ({ publisher, orders }) => {
   orders = orders.filter(el => !el.status);

   return (
      <>
         <nav className={s.publisherNav}>
            <div className={s.left}>
               <LogoTxt color="#202124" url="/publisher" />
            </div>

            <div className={s.middle}>
               <p className={s.registeredDomain}>{publisher.domain || `Add your site to earn money` }</p>
            </div>

            <div className={s.right}>
               <div className={s.balance}>
                  <div>
                     <p className={s.balanceTitle}>Balance:</p>
                     <Link className={s.transactions} to="/publisher/earnings">See transactions</Link>
                  </div>
                  <p className={s.amount}>${publisher.balance.toFixed(2)}</p>
               </div>
               <div className={s.navIcons}>
                  <NewsMarker fill={orders.length ? `#0B6AFF` : `#C6CED7`} count={orders.length || null} />
                  <Exit view="img" />
               </div>
            </div>
         </nav>
         <Outlet />
      </>
   )
}

export default PublisherNav