import { useSelector } from 'react-redux';
import s from './styles.module.css';
import Title from '../../components/Title'
import Text from '../../components/Text'
import Line from '../../components/Line'
import Notification from '../../components/Notification'

const Register = props => {
   const publisher = useSelector(state => state.user);
   const orders = useSelector(state => state.orders);
   const completed = orders.filter(el => el.status === `completed`);

   return (
      <>
         <div className={`${s.title} ${s.mini}`}>
            <Title text="Earnings" />
         </div>

         <div className={s.desc}>
            <Text text="Here you can see your income" />
         </div>

         <Line />

         <div className={s.row}>
            <h2>Balance:</h2>
            <h2>${publisher.balance.toFixed(2)}</h2>
         </div>

         <div className={s.notice}>
            {
               publisher.balance
                  ?
                  <Notification text="Minimum withdrawal amount is $1000" />
                  :
                  <Notification text="Complete an offer and get your first reward" />
            }
         </div>

         <Line />

         <div className={s.row}>
            <h2>Transactions:</h2>
         </div>

         {
            completed.map((el, i) => {
               return (
                  <div key={i} className={s.row}>
                     <p>Order #{el.id.slice(0, 6)}</p>
                     <p>${el.placement + el.writing}</p>
                  </div>
               )
            })
         }

         <Line />

         <div className={`${s.row} ${s.total}`}>
            <p>TOTAL:</p>
            <p>${publisher.balance.toFixed(2)}</p>
         </div>
      </>
   )
}

export default Register;
