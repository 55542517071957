import LogoTxt from '../../components/LogoTxt'
import s from './styles.module.css'

const Error404 = props => {
   return (
      <div className={s.centered}>
         <LogoTxt color="#202124" />
         <h1>#404</h1>
         <p>We couldn't find a page you were looking for </p>
      </div>
   )
}


export default Error404;