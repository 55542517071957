import s from './styles.module.css'

const Textarea = props => {

    return (
        <div className={s.textarea}>
            <p>{props.title}</p>
            <textarea ref={props.innerRef} placeholder={props.placeholder}>{props.text}</textarea>
        </div>

    )
}

export default Textarea