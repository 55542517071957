import { Link, useLocation } from 'react-router-dom'
import s from './styles.module.css';
import { handleQuit } from '../SideMenu';

const TopMenu = props => {
    const route = useLocation();

    return (
        <div className={`hrz-scroll hide-scrollbar ${s.topMenu}`}>
            <Link className={route.pathname === `/publisher` || route.pathname.includes(`/new`) ? s.active : null} to="/publisher">My Offers</Link>

            <Link className={route.pathname.includes(`completed`) ? s.active : null} to="/publisher/completed">Completed</Link>

            <Link className={route.pathname.includes(`earnings`) ? s.active : null} to="/publisher/earnings">Earnings</Link>

            <p onClick={handleQuit} >Quit</p>
        </div>
    )
}

export default TopMenu