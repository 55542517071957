import { useSelector, } from 'react-redux';
import s from './styles.module.css';
import Title from '../../components/Title'
import Text from '../../components/Text'
import Line from '../../components/Line'
import Offer from '../../components/Offer'
import Notification from '../../components/Notification'

const Completed = props => {
   const orders = useSelector(state => state.orders);
   const getCompletedOffers = () => {
      const completed = orders.filter(el => el.status === `completed`);
      const jsx = completed.map(offer => <Offer key={offer.id} {...offer} />);
      return jsx.length ? jsx : <Notification text="You don't have any completed orders. Accept one and complete the task" />
   }
   
   return (
      <>
         <div className={`${s.title} ${s.mini}`}>
            <Title text="Completed" />
         </div>

         <div className={s.desc}>
            <Text text="Here you can find successfully completed orders" />
         </div>

         <div className={s.desc}>
            <Line />
         </div>

         {
            getCompletedOffers()
         }
      </>
   )
}

export default Completed;