import req from '../utils/axios';
import { showError } from '../actions/error';
import { setUser } from '../actions/user';
import { setPotencial } from '../actions/potencial';
import { setOrders } from '../actions/orders';
export const PROMO_SET = `PROMO/SET`;

export const setPromo = promo => {
   return {
      type: PROMO_SET,
      promo
   }
}

export const getPromo = emailID => {
   return async (dispatch, getState) => {
      try {
         const { data: { publisher, potencial, orders, promo } } = await req.get(`/promos/${emailID}`);
         if (!promo) {
            window.location.href = `/registration`;
            return;
         }
         dispatch(setUser(publisher));
         dispatch(setPotencial(potencial));
         dispatch(setOrders(orders));
         dispatch(setPromo(promo));

      } catch (error) {
         dispatch(showError({ status: true, text: error.message }));
      }
   }
}