import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import Title from '../../components/Title'
import Text from '../../components/Text'
import Line from '../../components/Line'
import Options from '../../components/Options'
import Input from '../../components/Input'
import Button from '../../components/Button'
import s from './styles.module.css'
import { showLoader } from '../../actions/loader'
import { showError } from '../../actions/error'
import { setRegistered } from '../../actions/registration'
import Notification from '../../components/Notification'
import PublisherPromo from '../../components/PublisherPromo'
import TermsBox from '../../components/TermsBox'
import req from '../../utils/axios'



const Registration = props => {
   const options = useSelector(state => state.options);
   const isRegistered = useSelector(state => state.registration);
   const type = options.find(el => el.isActive).title.toLowerCase();

   return (
      <div className={s.register}>
         <div className={s.title}>
            <Title text="Register" />
         </div>

         <div className={s.desc}>
            <Text text="Start your journey with us" />
         </div>

         {
            (type === `buyer` && isRegistered)
               ?
               <Notification text="Thank you for registration. Our sales team will 
               contact you in 24 hours to have a little talk about your needs." />
               :
               <>
                  <Line />
                  <RegistrationForm />
               </>
         }

         <p className={s.haveAcc}>Have an account? <Link to="/login">Log in</Link></p>

         { (type === `buyer` && isRegistered) ? <PublisherPromo /> : null}
      </div>
   )
}

export default Registration


export const RegistrationForm = props => {
   const dispatch = useDispatch()
   const options = useSelector(state => state.options)
   const loader = useSelector(state => state.loader)
   const promo = useSelector(state => state.promo)
   const [email, changeEmail] = useState(``);
   const [pass1, changePass1] = useState(``);
   const [pass2, changePass2] = useState(``);
   const [status, changeTerms] = useState(null);

   const setTerms = e => changeTerms(!status);
   const setEmail = e => changeEmail(e.target.value);
   const setPass1 = e => changePass1(e.target.value);
   const setPass2 = e => changePass2(e.target.value);

   const handleSubmit = async e => {
      if (!email) {
         dispatch(showError({ status: true, text: `Email is required` }));
         return;
      }
      if (!pass1 || !pass2) {
         dispatch(showError({ status: true, text: `Password is required` }));
         return;
      }
      if (pass1 !== pass2) {
         dispatch(showError({ status: true, text: `Passwords doesn't match` }));
         return;
      }
      if (!status) {
         dispatch(showError({ status: true, text: `You have to accept our terms of use` }));
         return;
      }
      dispatch(showLoader(true));

      try {
         const type = options.find(el => el.isActive).title.toLowerCase();
         const { data: token } = await req.post(`/registration`, {
            type,
            email,
            domain: promo ? promo.domain : null,
            pass: pass1
         });

         if (type === `publisher`) {
            localStorage.setItem(`token`, token);
            window.location.href = `/publisher`;
         }

         dispatch(setRegistered(true));

      } catch (error) {
         if (error.response && error.response.data) {
            dispatch(showError({ status: true, text: error.response.data }));
         } else {
            dispatch(showError({ status: true, text: error.message }));
         }
      }

      dispatch(showLoader(false));
   }

   return (
      <>
         <div className={s.options}>
            <Options options={options} />
         </div>

         <Line />

         <div className={s.email}>
            <Input title="Email" placeholder="Email here…" value={email} onChange={setEmail} />
         </div>

         <div className={s.password}>
            <Input title="Password" placeholder="Type your password" type="password" value={pass1} onChange={setPass1} />
            <Input placeholder="Confirm password" type="password" value={pass2} onChange={setPass2} />
         </div>

         <div className={s.submit}>
            <Button
               style={{ color: "#fff", backgroundColor: "#0B6AFF" }}
               title="Register"
               onClick={handleSubmit}
               loader={loader} />
         </div>

         <div className={s.terms}>
            <TermsBox status={status} onChange={setTerms} />
         </div>
      </>
   )
}