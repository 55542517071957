import { useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom'
import s from './styles.module.css'
import { truncate } from '../../utils'
import Button from '../../components/Button'
import Notification from '../../components/Notification'
import Input from '../../components/Input'
import { showError } from '../../actions/error'
import { setOrder } from '../../actions/orders'
import req from '../../utils/axios'
import CopyButton from '../CopyButton';


const options = { year: `numeric`, month: `long`, day: `numeric` }
const Offer = props => {
    const promo = useSelector(state => state.promo);
    const renderButtons = status => {
        switch (status) {
            case `accepted`:
                return <DoneButton id={props.id} />;

            case `rejected`:
                return <RejectedButton id={props.id} />;

            case `reviewing`:
                return <ReviewingStatus />;

            case `returned`:
                return <ReturnedNotice id={props.id} reason={props.reason} />;

            case `completed`:
                return <CompletedNotice />;

            default:
                return <RegisteredButtons id={props.id} />;
        }
    }

    return (
        <div className={s.offer}>
            <div className={s.header}>
                <div className={s.buyer}>
                    <div>{truncate(props.buyer.email, 1)}</div>
                    <div className={s.email}>
                        <p>{`${truncate(props.buyer.email, 1)}****@****.com`}</p>
                        <p>{new Date(props.date).toLocaleDateString(`en-US`, options)}</p>
                    </div>
                </div>

                <div className={s.amount}>
                    <p>Total ${props.placement + props.writing}</p>
                    <p>Offer</p>
                </div>
            </div>

            <h2>{props.title}</h2>
            <p>{props.task}</p>

            <h3>TASK DETAILS:</h3>

            <div className={s.rows}>
                <div>
                    <p>You will earn for placement</p>
                    <div className={s.dotted}></div>
                    <p className={s.summ}>${props.placement}</p>
                </div>
                <div>
                    <p>You will earn for writing</p>
                    <div className={s.dotted}></div>
                    <p className={s.summ}>${props.writing}</p>
                </div>
                <div>
                    <p>Client origin</p>
                    <div className={s.dotted}></div>
                    <p>{props.buyer.origin}</p>
                </div>
                <div>
                    <p>Backlink</p>
                    <div className={s.dotted}></div>
                    <div className={s.copy}>
                        <CopyButton copyText={props.backlink} />
                        <div className={s.backlink}>
                            <p className="ellipsis">{props.backlink}</p>
                            <p>dofollow</p>
                        </div>
                    </div>
                </div>
                <div>
                    <p>Anchor</p>
                    <div className={s.dotted}></div>
                    <div className={s.copy}>
                        <CopyButton copyText={props.anchor} />
                        <div className={s.backlink}>
                            <p className="ellipsis">{props.anchor}</p>
                            <p>you can change it a bit</p>
                        </div>
                    </div>
                </div>
            </div>

            {
                promo ?
                    <NotRegisteredButtons promo={promo} />
                    :
                    renderButtons(props.status)
            }
        </div>
    )
}

const ReturnedNotice = props => {
    const dispatch = useDispatch();
    const [loader, setLoader] = useState(false);
    const token = localStorage.getItem(`token`);
    const inputRef = useRef(null);

    const handleDone = async e => {
        const postLink = inputRef.current.value;

        if (!postLink) {
            dispatch(showError({ status: true, text: `Guest post URL is required` }));
            return;
        }

        setLoader(true);
        try {
            const { data: order } = await req.put(`/order/done/${token}`, {
                id: props.id,
                postLink
            });

            if (order) dispatch(setOrder(order));

        } catch (error) {
            if (error.response && error.response.data) {
                dispatch(showError({ status: true, text: error.response.data }));
            } else {
                dispatch(showError({ status: true, text: error.message }));
            }
        }
        setLoader(false);
    }

    return (
        <div className={s.done}>
            <h3>WARNING: <span className={s.red}>RETURNED</span></h3>
            <p className={s.subline}>The buyer has comments about the work performed. Please fix problems then confirm.</p>
            
            <h3>FROM BUYER:</h3>
            <p className={s.subline}>{props.reason}</p>

            <Input innerRef={inputRef} placeholder="Guest post URL here…" />
            <Button
                style={{ color: "#fff", backgroundColor: "#48C966" }}
                title="Confirm fixes"
                onClick={handleDone}
                loader={loader} />
        </div>
    )
}

const CompletedNotice = props => {
    return (
        <Notification styles="green" text={`This order is successfully completed! Congrats!`} />
    )
}

const ReviewingStatus = props => {
    return (
        <Notification text={`Your post is under Buyer review. It may take up to 6 days`} />
    )
}

const RejectedButton = props => {
    const dispatch = useDispatch();
    const [loader, setLoader] = useState(false);
    const token = localStorage.getItem(`token`);

    const handleResume = async e => {
        setLoader(true);
        try {
            const { data: order } = await req.put(`/order/accept/${token}`, {
                id: props.id
            });

            if (order) window.location.href = `/publisher`;

        } catch (error) {
            if (error.response && error.response.data) {
                dispatch(showError({ status: true, text: error.response.data }));
            } else {
                dispatch(showError({ status: true, text: error.message }));
            }
        }
        setLoader(false);
    }

    return (
        <div className={s.done}>
            <h3>DO YOU WANT TO RESUME?</h3>
            <Button
                style={{ color: "#9AABBC", backgroundColor: "#F1F1F1" }}
                title="Resume and Accept"
                onClick={handleResume}
                loader={loader} />
        </div>
    )
}

const DoneButton = props => {
    const dispatch = useDispatch();
    const [loader, setLoader] = useState(false);
    const token = localStorage.getItem(`token`);
    const inputRef = useRef(null);

    const handleDone = async e => {
        const postLink = inputRef.current.value;

        if (!postLink) {
            dispatch(showError({ status: true, text: `Guest post URL is required` }));
            return;
        }

        setLoader(true);
        try {
            const { data: order } = await req.put(`/order/done/${token}`, {
                id: props.id,
                postLink
            });

            if (order) dispatch(setOrder(order));

        } catch (error) {
            if (error.response && error.response.data) {
                dispatch(showError({ status: true, text: error.response.data }));
            } else {
                dispatch(showError({ status: true, text: error.message }));
            }
        }
        setLoader(false);
    }

    return (
        <div className={s.done}>
            <h3>HAVE YOU DONE?</h3>
            <p className={s.subline}>Come back here when you are done to place a link with the written article</p>
            <Input innerRef={inputRef} placeholder="Guest post URL here…" />
            <Button
                style={{ color: "#fff", backgroundColor: "#48C966" }}
                title="Done"
                onClick={handleDone}
                loader={loader} />
        </div>
    )
}

const NotRegisteredButtons = ({ promo }) => {

    return (
        <div className={s.register}>
            <Notification text={`This offer is for ${promo.domain} publisher. Please register or login`} />
            <div className={s.buttons}>
                <Link to="/login">Login</Link>
                <Link to="/registration">Register</Link>
            </div>
        </div>
    )
}

const RegisteredButtons = props => {
    const dispatch = useDispatch();
    const [loaderAccept, setAcceptLoader] = useState(false);
    const [loaderReject, setRejectLoader] = useState(false);
    const token = localStorage.getItem(`token`);

    const handleAccept = async e => {
        setAcceptLoader(true);
        try {
            const { data: order } = await req.put(`/order/accept/${token}`, {
                id: props.id
            });

            if (order) dispatch(setOrder(order));

        } catch (error) {
            if (error.response && error.response.data) {
                dispatch(showError({ status: true, text: error.response.data }));
            } else {
                dispatch(showError({ status: true, text: error.message }));
            }
        }
        setAcceptLoader(false);
    }

    const handleReject = async e => {
        setRejectLoader(true);
        try {
            const { data: order } = await req.put(`/order/reject/${token}`, {
                id: props.id
            });

            if (order) window.location.href = `/publisher`;

        } catch (error) {
            if (error.response && error.response.data) {
                dispatch(showError({ status: true, text: error.response.data }));
            } else {
                dispatch(showError({ status: true, text: error.message }));
            }
        }
        setRejectLoader(false);
    }

    return (
        <div className={s.buttons}>
            <Button
                style={{ color: "#9AABBC", backgroundColor: "#F1F1F1" }}
                title="Reject"
                onClick={handleReject}
                loader={loaderReject} />

            <Button
                style={{ color: "#fff", backgroundColor: "#0B6AFF" }}
                title="Accept"
                onClick={handleAccept}
                loader={loaderAccept} />
        </div>
    )
}

export default Offer