import { useEffect } from 'react';
import { Outlet } from 'react-router-dom'
import Exit from '../components/Exit'
import { useDispatch, useSelector } from 'react-redux'
import { getBuyer } from '../actions/buyer';
import s from './styles.module.css';

const BuyerLayout = props => {
    const dispatch = useDispatch();
    const token = localStorage.getItem(`token`);
    const buyer = useSelector(state => state.buyer);

    useEffect(() => {
        if (token) {
            dispatch(getBuyer(token));
            return;
        }
        if (!token) {
            window.location.href = `/buyer/login`;
            return;
        }

    }, [dispatch, token]);

    if (buyer) {
        return (
            <>
                <div className={s.exitWrap}>
                    <Exit />
                </div>
                <Outlet />
            </>
        )
    }

    return <p>Loading...</p>
}

export default BuyerLayout;