import { Outlet, Link } from 'react-router-dom'
import Logo from '../LogoImg'
import { ICONS } from '../../constants'
import s from './styles.module.css'



const PublicNav = props => {

   return (
      <>
         <nav className={s.publicNav}>
            <Logo />

            <div className={s.flexCenter}>
               <Link to="/login">Publishers</Link>
               <Link to="/login">Buyers</Link>
               <Link to="/registration">Registration</Link>
               <Link to="/login">Login</Link>
               <img src={ICONS.inc} alt="Inc" />
            </div>
         </nav>
         <Outlet />
      </>

   )
}

export default PublicNav