

const Text = props => {

   return (
      <p>{props.text}</p>

   )
}

export default Text;