import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import s from './styles.module.css';
import Button from '../../components/Button';
import { showError } from '../../actions/error'
import { setReason } from '../../actions/buyer'
import req from '../../utils/axios'

const BuyerPanel = props => {
    const dispatch = useDispatch();
    const buyer = useSelector(state => state.buyer);
    const token = localStorage.getItem(`token`);

    const handleReturn = async id => {
        const order = buyer.orders.filter(el => el.id === id)[0];
        const reason = order ? order.reason : null;

        if (!reason) {
            dispatch(showError({ status: true, text: `Reason is required` }));
            return;
        }

        try {
            const { data: order } = await req.put(`/order/return/${token}`, {
                id,
                reason
            });

            if (order) window.location.href = `/buyer/panel`;

        } catch (error) {
            if (error.response && error.response.data) {
                dispatch(showError({ status: true, text: error.response.data }));
            } else {
                dispatch(showError({ status: true, text: error.message }));
            }
        }
    }

    const handleAccept = async id => {
        try {
            const { data: order } = await req.put(`/order/confirm/${token}`, {
                id
            });

            if (order) window.location.href = `/buyer/panel`;

        } catch (error) {
            if (error.response && error.response.data) {
                dispatch(showError({ status: true, text: error.response.data }));
            } else {
                dispatch(showError({ status: true, text: error.message }));
            }
        }
    }

    return (
        <>
            {
                buyer.orders.map(order => {
                    return (
                        <div key={order.id} className={s.order}>
                            
                            <p>{order.domain}</p>
                            <p>{new Date(order.date).toLocaleDateString(`en-US`)}</p>
                            <a href={order.postLink} className="ellipsis" target="_blank" rel="noopener noreferrer">{order.postLink}</a>

                            <textarea placeholder="The reason of return" value={order.reason || ``} onChange={e => dispatch(setReason(order.id, e.target.value))} />
                            <div className={s.flex}>
                                <LoadButton
                                    color="#9AABBC"
                                    backgroundColor="#F1F1F1"
                                    title="Return"
                                    onClick={handleReturn}
                                    id={order.id} />

                                <LoadButton
                                    color="#fff"
                                    backgroundColor="#0B6AFF"
                                    title="Accept"
                                    onClick={handleAccept}
                                    id={order.id} />
                            </div>
                        </div>
                    )
                })
            }
        </>
    )
}

const LoadButton = props => {
    const [loader, setLoader] = useState(false);

    const handleClick = async id => {
        setLoader(true);
        await props.onClick(props.id);
        setLoader(false);
    }

    return (
        <Button
            style={{ color: props.color, backgroundColor: props.backgroundColor }}
            title={props.title}
            onClick={handleClick}
            loader={loader} />
    )
}

export default BuyerPanel;