import { Outlet } from 'react-router-dom'
import Error from '../components/Error'

const EmptyLayout = props => {

    return (
        <>
            <Error />
            <Outlet />
        </>
    )
}

export default EmptyLayout