import { PROMO_SET } from '../actions/promo'

export const promo = (state = null, action) => {
   switch (action.type) {
      case PROMO_SET:
         return action.promo;

      default: return state;
   }
}

export default promo;